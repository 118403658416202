@use "@/styles/constants/devices.scss" as *;

.teamRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  min-height: 40px;
}

.teamLogoAndNameContainer {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    font-weight: bold;
    font-size: 0.8rem;
    margin: 0;
  }

  @media only screen and (min-width: $mobile) {
    gap: 15px;
  }
}

.oddsButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--matchCenter-borderColor);
  background-color: var(--matchCenter-oddsContainerBackgroundColor);
  width: 50px;

  &[aria-pressed="true"] {
    background-color: var(--matchCenter-activeSelectionBackgroundColor);
  }

  div {
    padding: 6px 20px;
  }

  p {
    font-weight: bold;
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.15;
  }

  p:first-child {
    font-size: 0.8rem;
    text-align: center;
  }

  p:last-child {
    font-size: 0.5rem !important;
    text-align: center;
    color: var(--matchCenter-secondaryBackgroundColor);
  }

  @media only screen and (min-width: $mobile) {
    width: 56px;
  }
}
