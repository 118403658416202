@use "@/styles/constants/devices.scss" as *;

.container {
  background-color: var(--matchCenter-primaryBackgroundColor);
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap-reverse;
  margin: 0 0 16px 0;
  padding: 15px 20px 15px 10px;
  border-radius: 0;
  gap: 5px;

  p {
    font-size: 0.6rem !important;
  }

  @media only screen and (min-width: $mobile) {
    flex-wrap: unset;
    padding: 15px 0 15px 15px;
    border-radius: 8px;
    gap: 15px;

    p {
      font-size: 0.8rem !important;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media only screen and (min-width: $mobile) {
    flex: 0.1;
    display: block;
    min-width: 150px;
  }
}

.gradient {
  position: absolute;
  background: var(--matchCenter-primaryBackgroundColor);
  box-shadow: 10px 0 5px -2px var(--matchCenter-primaryBackgroundColor);
  opacity: 0.85;
  left: 0;
  width: 20px;
  height: 60%;

  @media only screen and (min-width: $mobile) {
    left: 165px;
    height: 90%;
  }
}

.selectContainer {
  display: flex;
  padding: 0 10px 0 5px;

  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid var(--matchCenter-borderColor);

  select {
    background: var(--matchCenter-primaryBackgroundColor)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAACqADAAQAAAABAAAABwAAAADD6HxEAAAAqElEQVQYGWNgIBIwLlu2MvL/f0Z/dXWlWBMTk9/I+s6cOcN68+a9xYyM/zcyARU5////P/zmzbtrt23bxg5TCGKDxEByIDWMQAbj0qWrpgMVpDMyMuyUkhILACl+9uzVhv//GdyBzJnR0WGZjCBBqOKJQGYuIyPjPqiYE5CeHBMTngfigxWCGCCwdOnq7v///5WA2IyMTD3R0aGlIDZWsHTpymYQRpcEAC5jTDXlWzsxAAAAAElFTkSuQmCC)
      no-repeat right center;
    font-size: 0.6rem;
    font-weight: bold;
    outline: none;
    border: none;
    appearance: none;
    padding: 8px;
    min-width: 100px;

    @media only screen and (min-width: $mobile) {
      font-size: 0.8rem;
      padding: 10px 40px 10px 10px;
      width: 100%;
    }
  }
}

.fixturesContainer {
  display: flex;
  align-items: flex-start;
  overflow: scroll;
  scroll-behavior: smooth;
  order: -1;
  flex: 1 0 100%;
  gap: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: $mobile) {
    order: unset;
    flex: 1;
    gap: 15px;
  }
}

.navButtonsContainer {
  display: flex;
  flex-direction: column;
  align-self: normal;
  box-shadow: -45px 0 40px var(--matchCenter-primaryBackgroundColor);
  margin: -15px 0 -15px auto;

  button {
    user-select: none;
  }

  button:first-child {
    background-color: var(--matchCenter-secondaryBackgroundColor);
    border: none;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40px;
    color: var(--matchCenter-primaryBackgroundColor);
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid var(--matchCenter-borderColor);
  }

  button:nth-child(2) {
    background-color: var(--matchCenter-secondaryBackgroundColor);
    border: none;
    flex: 1;
    width: 40px;
    color: var(--matchCenter-primaryBackgroundColor);
    border-radius: 0 0 8px 0;
  }

  button:disabled {
    pointer-events: none;
  }

  // Hidden for mobiles
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}

.disableBoxShadow {
  box-shadow: unset;
}

.noMatchesMessage {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0.5rem 0;
  order: -1;

  @media only screen and (min-width: $mobile) {
    order: unset;
    margin: auto;
  }
}
