@use "@/styles/constants/devices.scss" as *;

.eventCard {
  border-radius: 4px;
  border: 1px solid var(--matchCenter-borderColor);
  padding: 10px;
  min-width: 150px;

  @media only screen and (min-width: $mobile) {
    min-width: 180px;
  }
}

.matchInfo {
  font-size: 0.55rem;
  color: var(--matchCenter-matchInfoFontColor);
  letter-spacing: 0.03rem;
}
